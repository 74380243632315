<template>
    <div>
        <div class="single">
            <img src="../src/assets/bg.jpg" class="img-fluid" alt="">
            <div class="container-fluid content_first">
                <div class="row align-items-center justify-content-center">
                    <div class="col">
                        <h2 class="display-2">PipeSer管线管网云服务</h2>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <div class="col-2  ">
                        <a class="a1"
                            href="../PipeSer%E7%AE%A1%E7%BA%BF%E7%AE%A1%E7%BD%91%E4%BA%91%E6%9C%8D%E5%8A%A1.pdf"
                            target="_blank">介绍文档</a>
                    </div>
                    <div class="col-2">
                        <a class="a1" href="../models/PipeSer.pdf" target="_blank">用户手册</a>
                    </div>
                    <div class="col-2">
                        <a class="a1" href="../login.html" target="_blank">在线使用</a>
                    </div>
                    <div class="col-2">
                        <a class="a1" href="../pipeser.zip" target="_blank">离线部署</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">01</h1>
                        <div class="sangle_left"></div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>所见即所得</h3>
                    <div class="col-11 col-md-7 direction">
                        <span>可能是最轻量的管线建模方式，不需要3dsMax，不需要Revit，不需要Arcgis，直接浏览器内拖拖拽拽。建模成果就是最终系统里效果，抛弃—切中间环节</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content ">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">02</h1>
                        <div class="sangle_left"></div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>无限承载</h3>
                    <div class="col-11 col-md-7 direction">
                        <span>园区上万管点，城区十万管点，城市百万管点，甚至千万。从设计之初就为承载大数据量而来，未知边界，无限能力</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">03</h1>
                        <div class="sangle_left"></div>

                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>标准服务</h3>
                    <div class="col-11 col-md-7 direction">
                        <span>标准3dtiles三维模型服务，直接接入Cesium，Unreal Engine等引擎中。视野所及，数据实时产生，无需缓存过程</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">04</h1>
                        <div class="sangle_left"></div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>丰富的材质库</h3>
                    <div class="col-11 col-md-7 direction">
                        <span>内嵌砼、PVC、PE、石砌、砖砌等10多种预定义材质。当然提供了用户自定义的PBR实时材质编辑器，您似乎可以摒弃Cesium材质效果不好的印象。</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">05</h1>
                        <div class="sangle_left"></div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>免费的附属物库</h3>
                    <div class="col-11 col-md-7 direction">
                        <span>内置检查井、接线箱、篦子、路灯、交通信号灯等20余种带LOD的附属物库免费使用。当然您也可以上传自己的附属物模型，做自己项目的专属库。</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">06</h1>
                        <div class="sangle_left"></div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>灵活的数据导入</h3>
                    <div class="col-11 col-md-7 direction">
                        <span>实在难以保证数据入库的结构要求匹配每个人的数据组织形式。作为合格的码农，您可以编辑js脚本，根据你的逻辑来灵活组织数据，并且支持用户自定义字段</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">07</h1>
                        <div class="sangle_left"></div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>无限制的图层配置</h3>
                    <div class="col-11 col-md-7 direction">
                        <span>您可以按管线类型分图层，按行政区划分图层，或者既要按管线类型也要按行政区划，您想怎么来都行。我们要做的依然保证数据的显示效率，保证您可以按照图层去控制颜色和可见性</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">08</h1>
                        <div class="sangle_left"></div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>私有化部署</h3>
                    <div class="col-11 col-md-7 direction">
                        <span>这不是一个单机工具，这是一个云服务，一个可以部署到您内网的私有云服务，您可以分配给您的用户不同的数据权限，每个用户可以拥有多个管线项目，每个项目可以有不同的配置</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">09</h1>
                        <div class="sangle_left"></div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>二次开发</h3>
                    <div class="col-11 col-md-7 direction">
                        <span>提供了100多个rest风格的http服务接口，您可以在您的项目网页系统里操作数据的增加改查、材质修改、几何参数修改、甚至是联通性分析、剖面分析等</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="single">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="container-fluid content">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 align-self-center row_center">
                        <div class="sangle_right"></div>
                        <h1 class="display-2">10</h1>
                        <div class="sangle_left"></div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center ">
                    <h3>眼见为实</h3>
                    <div class="col-11 col-md-7 direction" id="lastspan">
                        <span>是骡子是马,您来溜溜就知道了，立即</span><a class="a2" href='https://www.pipeser.com/login.html'
                            target="_blank">注册使用</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="single_big">
            <img src="../src/assets/bg02.jpg" class="img-fluid" alt="">
            <div class="content pipe_last">
                <h3>价格体系</h3>
                <table class="table1">
                    <tr>
                        <th>单项目管点数量限制</th>
                        <th><span class="important">pipeser.com<br>在线使用</span></th>
                        <th><span class="important">离线部署</span>使用时间1年+<br>有效期内免费版本升级（元）</th>
                        <th><span class="important">离线部署</span>无使用时间限制+<br>三年免费版本升级（元）</th>
                    </tr>
                    <tr class="color1">
                        <td>1万管点（园区级）</td>
                        <td>0元</td>
                        <td>5000/个</td>
                        <td>25000/个</td>
                    </tr>
                    <tr class="color2">
                        <td>10万管点（区县级）</td>
                        <td>0元</td>
                        <td>16000/个</td>
                        <td>50000/个</td>
                    </tr>
                    <tr class="color1">
                        <td>100万管点（城市级）</td>
                        <td>0元</td>
                        <td>40000/个</td>
                        <td>100000/个</td>
                    </tr>
                    <tr class="color2">
                        <td>不限制</td>
                        <td>0元</td>
                        <td>80000/个</td>
                        <td>200000/个</td>
                    </tr>
                </table>
                <!-- <table class="table2">
                    <tr class="color1">
                        <td class="td">1</td>
                        <td>所有报价均为含税报价，专票税率13%</td>
                    </tr>
                    <tr class="color2">
                        <td class="td">2</td>
                        <td>一次性付费超过5W，赠送PipeSer前端编辑器代码</td>
                    </tr>
                    <tr class="color1">
                        <td class="td">3</td>
                        <td>2021年8月31日前，一次性付费超10W，总价9折，一次性付费超20W，总价8折</td>
                    </tr>
                </table> -->
                <div class="last">
                    <div class="information">
                        <p>诚意满满且满怀希望与您携手合作:16619955894(苏经理)</p>
                        <div class="information_content">
                            <img src="../src/assets/qun.png" class="img_responsive" alt="">
                            <div class="information_end">
                                <p>名称：PipeSer免费技术交流群</p>
                                <p>群号：297248626</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="last">
                    <div>
                        <!-- <a href="http://www.cesiumlab.com/">CesiumLab官网</a>
                        <a href="http://www.earthsdk.com/">EarthSDK官网</a> -->
                        <a href="https://bjxbsj.cn/"> 北京西部世界科技有限公司</a>
                    </div>
                    <div>
                        <a href="https://beian.miit.gov.cn/#/Integrated/index">© 2021 北京西部世界科技有限公司.
                            京ICP备17022303号-5</a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    export default {
        name: 'App',
        components: {},
        mounted() {
            console.log("高度", window.innerWidth)
        },
        methods() {
            window.location.href = "/login.html";
        }
    }
</script>

<style scoped>
    .a1 {
        background: #288DFB;
        color: #fff;
        border: none;
        padding: 0.4vw;
        padding-right: 1.5vw;
        padding-left: 1.5vw;
        text-decoration: none;
    }

    a {
        color: #fff;
        text-decoration: none;
        padding-right: 2vw;
    }

    .a2 {
        background: #288DFB;
        color: #fff;
        border: none;
        padding: 0.4vw;
        padding-right: 1vw;
        padding-left: 1vw;
        text-decoration: none;
        font-size: 1vw;

    }

    .a2:hover,
    .a1:hover {
        background: #df7512;
    }

    h1 {
        font-size: 13vw;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }

    h2 {
        font-size: 8vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
    }

    h3 {
        font-size: 4vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
    }

    p {
        margin: 0;
        padding: 0;
        text-align: start;
    }


    .single {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .single_big>img {
        display: block;
        width: 100%;
        padding: 0 !important;
    }

    .single>img {
        display: block;
        width: 100%;
        padding: 0 !important;

    }

    .content_first {
        position: absolute;
        text-align: center;
        z-index: 1000;
        color: #fff;
        font-size: 1vw;
    }

    .content_first>div:nth-child(1) {
        margin-bottom: 14vw;
    }

    .content {
        position: absolute;
        text-align: center;
        z-index: 1000;
        color: #fff;
        font-size: 1vw;
    }

    .content>div:nth-child(1) {
        margin-bottom: 5vw;
    }


    .company_name {
        border: 0.3vw solid #FFFFFF;
    }

    .sangle_right {
        align-self: flex-end;
        width: 0;
        height: 0;
        border-right: 3vw solid #fff;
        border-bottom: 3vw solid transparent;
        opacity: 0.4;
        z-index: 1000;
        margin-bottom: -4vw;
    }

    .sangle_left {
        align-self: flex-start;
        width: 0;
        height: 0;
        border-left: 3vw solid #fff;
        border-top: 3vw solid transparent;
        opacity: 0.4;
        z-index: 1000;
        margin-top: -4vw;

    }


    .row_center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 4vw;
        padding-left: 4vw;
    }

    .direction {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 12px #000000;
        margin-top: 4vw;
        border-radius: 8px;
        color: #fff !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 1.5vw;
        text-align: start;
    }

    .direction>span {
        display: inline-block;
        padding: 2vw;
        padding-right: 1vw;
        padding-left: 2vw;
        color: #fff !important;
        opacity: 1;
    }

    #lastspan {
        text-align: center !important;
    }


    .single_big {
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    p {
        text-align: center;
    }

    .table1>tr>th,
    .table1>tr>td {
        font-size: 1.2vw !important;
        width: 20vw;
    }


    .table2>tr>td:nth-child(1) {
        font-size: 1.2vw !important;
        width: 5vw;
        background: rgba(0, 0, 0, 0.6);
    }

    .table2 {
        margin-top: 2vw;
    }

    .table2>tr>td:nth-child(2) {
        font-size: 1.2vw !important;
        width: 75vw;
    }

    .table1>tr>th {
        background: rgba(0, 0, 0, 0.5);
    }

    .color1>td {
        background: rgba(54, 53, 53, 0.5);
    }

    .color2>td {
        background: rgba(138, 133, 133, 0.5);
    }

    .last {
        width: 80vw;
        margin-top: 2vw;
        background: rgba(54, 53, 53, 0.5);
    }

    .pipe_last {
        margin: auto;
    }

    .lianxifangshi {
        display: flex;
    }

    .img_responsive {
        display: block;
        height: auto;
        max-width: 10vw;
        max-height: 10vw;
    }

    .information {
        font-size: 1.2vw;
        width: 40vw;
        margin: 0 auto;
    }

    .information_content {
        display: flex;
    }

    .information_end {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding-left: 2vw;
    }

    .important {
        color: red
    }
</style>
